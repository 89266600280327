import React from 'react'

import TopNav from './../components/TopNav'
import Footer from './../components/Footer'
import Expandable from './../components/Expandable'

import FAQ from './../images/faq.jpg'

function Page() {
  return (
    <div>
      <TopNav active="faq" />
      <div className="slideInTop">

        <section>
          <div>
            <img src={FAQ} alt="" className="w-100 blocked force" style={{ height: 512 }} />
          </div>
        </section>

        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">سولات متداول توسط شما</h3>
            <hr />

            <div className="row justify-c py-5">
              <div className="col-md-10">
                {
                  [0, 1, 2, 3, 4, 5, 6].map(item => (
                    <Expandable key={item} item={{
                      question: "متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا",
                      answer: "متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا"
                    }} />
                  ))
                }
              </div>
            </div>

          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
