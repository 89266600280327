import React from 'react'

import TopNav from './../components/TopNav'
import Footer from './../components/Footer'
import Card from './../components/Card'

import Services from './../images/services.jpg'

import Blur from './../images/blur.jpg'

function Page() {
  return (
    <div>
      <TopNav active="gallery" />
      <div className="slideInTop">

        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">گالری ما</h3>
            <hr />


            <div className="row pt-5">
              <div className="col-md-6 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 pb-4">
                    <img src={Blur} alt="" className="w-100 force" />
                  </div>
                  <div className="col-md-6 pb-4">
                    <img src={Blur} alt="" className="w-100 force" />
                  </div>
                  <div className="col-md-6 pb-4">
                    <img src={Blur} alt="" className="w-100 force" />
                  </div>
                  <div className="col-md-6 pb-4">
                    <img src={Blur} alt="" className="w-100 force" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
              <div className="col-md-3 pb-4">
                <img src={Blur} alt="" className="w-100 force" />
              </div>
            </div>

          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
