import React from 'react'
// import { Link } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'

import Logo from './../images/main-logo.png'
import Abadgaran from './../images/abadgaran.png'

export default ({ active }) => {
  return (
    <Navbar bg="light" expand="lg" className="shadow-sm">
      <div className="container">
        <Navbar.Brand href="/">
          <img src={Logo} className="radius-4 ml-3" style={{ width: 64 }} alt="" />
          <img src={Abadgaran} className="radius-4 ml-3" style={{ width: 64 }} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto pr-3 pt-1">
            <Nav.Link href="/" className={active === 'home' && 'text-info bold'}>خانه</Nav.Link>
            <Nav.Link href="/gallery" className={active === 'gallery' && 'text-info bold'}>گالری</Nav.Link>
            <Nav.Link href="/services" className={active === 'services' && 'text-info bold'}>خدمات</Nav.Link>
            <Nav.Link href="/about" className={active === 'about' && 'text-info bold'}>درباره ما</Nav.Link>
            <Nav.Link href="/contact" className={active === 'contact' && 'text-info bold'}>ارتباط با ما</Nav.Link>
            {/* <Nav.Link href="/faq" className={active === 'faq' && 'text-info bold'}>سوالات متداول</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}