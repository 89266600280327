import React from "react"
import { useState } from "react"

export default ({ item }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="mb-3 radius-4" style={{ backgroundColor: '#f1f1f1' }}>
      <div className="button pointer flexed justify-between p-3" onClick={() => setExpanded(!expanded)}>
        <h4 className="m-0 font-16">{item.question}</h4>
        <div>
          <i className="fas fa-chevron-down mr-3" style={{ lineHeight: 1.9, transform: expanded ? 'rotate(180deg)' : '' }}></i>
        </div>
      </div>
      {expanded && <div className="px-3 pb-3 slideInBottom">
        <p className="m-0 line-2">{item.answer}</p>
      </div>}
    </div>

  )
}
