import React from 'react'

import TopNav from './../components/TopNav'
import Footer from './../components/Footer'

import Contact from './../images/contact.jpg'
import Map from './../images/map.jpg'

function Page() {
  return (
    <div>
      <TopNav active="contact" />
      <div className="slideInTop">

        <section>
          <div>
            <img src={Contact} alt="" className="w-100 blocked force" style={{ height: 512 }} />
          </div>
        </section>

        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">راه های ارتباطی ما</h3>
            <hr />

            <div className="row justify-c">
              <div className="col-md-10">
                <div className="mb-5 pt-5 flexed justify-between">
                  <div>
                    <a dir="ltr" href="" className="pl-3">
                      <i className="fas fa-phone pr-1 pt-1"></i>
                      041 33381260-4
                  </a>
                  </div>
                  <div>
                    <a dir="ltr" href="" className="pl-3">
                      <i className="fab fa-instagram pr-1 pt-1"></i>
                      abadgaran
                  </a>
                    <a dir="ltr" href="" className="">
                      <i className="fab fa-telegram pr-1 pt-1"></i>
                      abadgaran
                  </a>
                  </div>
                </div>

                <div>
                  <p>
                    <i className="fas fa-map-marker-alt pl-1 pt-1"></i>
                  تبریز - خیابان آزادی-نرسیده به شیخ الرئیس - خیابان جدیری جنوبی - مجموعه رفاهی ورزشی آبادگران</p>
                </div>
              </div>
            </div>

            <div className="mb-5 pt-5">
              <img src={Map} alt="" className="w-100" />
            </div>

            <div className="row justify-c">
              <div className="col-md-10">
                <p className="pt-3 line-2">
                  مجموعه رفاهی ورزشی آبادگران از سال 1391فعالیت خود را  با دارا بودن استخر استاندارد و تالارهای پذیرایی، غار نمک، سالن ماساژ و سالن بدنسازی افتخار ارائه خدمات ورزشی ورفاهی جهت ارتقا سلامت اجتماعی شروع نمودو با برگزاری مسابقات بین المللی شنا کشورهای اسلامی آسیا به افتخارات خدمتگزاری خود افزود.
                </p>
              </div>
            </div>

          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
