import React from 'react'
import { Button } from 'react-bootstrap'

import TopNav from './../components/TopNav'
import Card from './../components/Card'
import Footer from './../components/Footer'

// import Abadgaran from './../images/abadgaran.png'
// import Hero from './../images/hero-img.png'
// import Swimming from './../images/swimming.jpg'

// import Img3 from './../images/services/img3.jpg'
import Pool from './../images/services/pool.jpeg'
import Hall from './../images/services/hall.jpg'
import Cafeteria from './../images/services/cafeteria.jpg'
import Gym from './../images/services/gym.jpg'

import Avatar from './../images/avatar.jpg'

import As from './../images/as.svg'

const services = [
  {
    img: Pool,
    title: 'استخر',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Hall,
    title: 'تالار',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Cafeteria,
    title: 'کافه تریا',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Gym,
    title: 'سالن بدنسازی و یوگا',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  }
]

function Page() {
  return (
    <div>
      <TopNav active="home" />
      <div className="slideInTop">

        <section className="hero" style={{ height: 'calc(100vh - 78px)', maxHeight: 700 }}>
          <div className="h-100 flexed align-c" style={{ background: '#333333a6' }}>
            <div className="container">
              <h4 className="text-center text-light">مجتمع رفاهی ورزشی آبادگران</h4>
              <h1 className="text-center text-white pt-4">آبــادگـــــــران</h1>
              <p className="text-white pt-4 mx-auto line-2" style={{ maxWidth: 450 }}>
                مجموعه رفاهی ورزشی آبادگران از سال 1391فعالیت خود را  با دارا بودن استخر استاندارد و تالارهای پذیرایی، غار نمک، سالن ماساژ و سالن بدنسازی افتخار ارائه خدمات ورزشی ورفاهی جهت ارتقا سلامت اجتماعی شروع نمودو با برگزاری مسابقات بین المللی شنا کشورهای اسلامی آسیا به افتخارات خدمتگزاری خود افزود.
              </p>

              {/* <div className="text-center mt-5">
                <h1 className="abadgaran radius-4">
                  <span className="hidden">آبادگران</span>
                  <img src={Abadgaran} alt="" />
                </h1>
                <img src={Hero} alt="" style={{ filter: 'hue-rotate(128deg)' }} />
              </div> */}


              <div className="ltr p-3 text-center mt-3">
                <Button variant="info" className="mx-2 radius-32">
                  <i className="fab fa-instagram pr-1 pt-1"></i>
                abadgaran
              </Button>
                <Button variant="outline-info" className="mx-2 radius-32">
                  <i className="fab fa-telegram pr-1 pt-1"></i>
                abadgaran
              </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-sky pt-5">
          <div className="container py-3">
            <h3 className="text-center">تعدادی از خدمات ما</h3>
            <hr />

            <div className="row pt-5">
              {
                services.map((service, i) => (
                  <div className="col-md-3 pb-4" key={i}>
                    <Card item={service} className="mx-auto" style={{ maxWidth: 300 }} />
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        {/*
        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">دیگران درباره ما چه می گویند</h3>
            <hr />

            <div className="row justify-c pt-5">
              <div className="speech col-md-8 mb-3">
                <div className="p-3 radius-8" style={{ background: '#f6fbff' }}>
                  <img src={Avatar} alt="" className="circle thumb right ml-3" />
                  <div>
                    <strong>سینا محمودی</strong>
                    <p className="pt-3 mb-0 line-2">
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                    </p>
                  </div>
                </div>
              </div>
              <div className="speech col-md-8 mb-3">
                <div className="p-3 radius-8" style={{ background: '#fff8fa' }}>
                  <img src={Avatar} alt="" className="circle thumb right ml-3" />
                  <div>
                    <strong>سینا محمودی</strong>
                    <p className="pt-3 mb-0 line-2">
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                    </p>
                  </div>
                </div>
              </div>
              <div className="speech col-md-8 mb-3">
                <div className="p-3 radius-8" style={{ background: '#ffffeb' }}>
                  <img src={Avatar} alt="" className="circle thumb right ml-3" />
                  <div>
                    <strong>سینا محمودی</strong>
                    <p className="pt-3 mb-0 line-2">
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                      متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        <section className="pt-5 pb-3">
          <div className="container py-3">
            <h3 className="text-center">طرف قراردادها</h3>
            <hr />

            <div className="row pt-5 justify-c">
              {
                ['تراکتورسازی تبریز', 'نظام مهندسی استان', 'داروسازی رازی', 'داروسازی دانا'].map((item, i) => (
                  <div className="col-md-2 col-xs-6 text-center mb-4" key={i}>
                    <img src={As} alt="" className="thumb-sm blocked mx-auto mb-3" />
                    <br />
                    <span>{item}</span>
                  </div>
                ))
              }
            </div>
          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
