import React from 'react'

import TopNav from './../components/TopNav'
import Footer from './../components/Footer'
import Card from './../components/Card'

import Services from './../images/services.jpg'

// import Img3 from './../images/services/img3.jpg'
import Pool from './../images/services/pool.jpeg'
import Hall from './../images/services/hall.jpg'
import Cafeteria from './../images/services/cafeteria.jpg'
import Gym from './../images/services/gym.jpg'
import Salt from './../images/services/salt.jpg'
import Massage from './../images/services/massage.jpg'

const services = [
  {
    img: Pool,
    title: 'استخر',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Hall,
    title: 'تالار',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Cafeteria,
    title: 'کافه تریا',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Gym,
    title: 'سالن بدنسازی و یوگا',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Salt,
    title: 'غارنمک',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  },
  {
    img: Massage,
    title: 'سالن ماساژ',
    desc: 'متن ساختگی الکی برای پر کردن فضا و بهتر دیده شدن محتوا',
    disabled: true
  }
]

function Page() {
  return (
    <div>
      <TopNav active="services" />
      <div className="slideInTop">

        <section>
          <div>
            <img src={Services} alt="" className="w-100 blocked force" style={{ height: 512 }} />
          </div>
        </section>

        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">خدماتی که ارائه میدهیم</h3>
            <hr />


            <div className="row pt-5">
              {
                services.map((service, i) => (
                  <div className="col-md-3 pb-4" key={i}>
                    <Card item={service} className="mx-auto" style={{ maxWidth: 300 }} />
                  </div>
                ))
              }
            </div>

          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
