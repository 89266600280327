import React from 'react';
import './css/App.css';
import { Switch, Route, Router } from "react-router-dom";
import history from "./history";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';

import Index from './pages';
import About from './pages/about';
import Contact from './pages/contact';
import FAQ from './pages/faq';
import Services from './pages/services';
import Gallery from './pages/gallery';

function App() {
  return (
    <Router history={history}>
      <div dir="rtl" className="App">
        <ToastContainer enableMultiContainer position={toast.POSITION.BOTTOM_RIGHT} />
        {/* <Suspense fallback={<BusyIndicator open />}> */}
        <Switch>
          <Route component={Gallery} path="/gallery" />
          <Route component={Services} path="/services" />
          <Route component={FAQ} path="/faq" />
          <Route component={Contact} path="/contact" />
          <Route component={About} path="/about" />
          <Route component={Index} />
        </Switch>
        {/* </Suspense> */}
      </div>
    </Router>
  );
}

export default App;
