import React from 'react';
import { Card, Button } from 'react-bootstrap'

export default function Component({ item, style, className }) {
	return (
		<Card style={style} className={className}>
			<Card.Img variant="top" src={item?.img} height={164} />
			<Card.Body>
				<Card.Title>{item?.title}</Card.Title>
				{/* <Card.Text>{item?.desc}</Card.Text> */}
				<br />
				<Button variant="info" className="radius-32" disabled={item.disabled}>مشاهده کامل</Button>
			</Card.Body>
		</Card>
	)
}