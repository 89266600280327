import React from 'react';
import { Link } from 'react-router-dom'

export default function Component() {
	return (
		<footer className="bg-info pt-5 pb-3">
			<div className="container">
				<div className="row">

					<div className="col-md-4">
						<strong className="text-light">آبادگران</strong>
						<div className="mt-3">
							<p className="text-light m-0 line-2">
								مجموعه رفاهی ورزشی آبادگران از سال 1391فعالیت خود را  با دارا بودن استخر استاندارد و تالارهای پذیرایی، غار نمک، سالن ماساژ و سالن بدنسازی افتخار ارائه خدمات ورزشی ورفاهی جهت ارتقا سلامت اجتماعی شروع نمودو با برگزاری مسابقات بین المللی شنا کشورهای اسلامی آسیا به افتخارات خدمتگزاری خود افزود.
						</p>
						</div>
					</div>
					<hr className="w-100 to-show" />
					<div className="col-md-4">
						<strong className="text-light">صفحات اصلی</strong>
						<div className="mt-3">
							<ul>
								<li className="mb-2"><Link to="/" className="text-light">خانه</Link></li>
								<li className="mb-2"><Link to="/gallery" className="text-light">گالری</Link></li>
								<li className="mb-2"><Link to="/services" className="text-light">خدمات</Link></li>
								<li className="mb-2"><Link to="/about" className="text-light">درباره ما</Link></li>
								<li className="mb-2"><Link to="/contact" className="text-light">ارتباط با ما</Link></li>
								{/* <li className="mb-2"><Link to="/faq" className="text-light">سوالات متداول</Link></li> */}
							</ul>
						</div>
					</div>
					<hr className="w-100 to-show" />
					<div className="col-md-4">
						<strong className="text-light">صفحات مجازی</strong>
						<div className="mt-3">
							<a dir="ltr" href="" className="text-light">
								<i className="fab fa-instagram pr-1 pt-1"></i>
                    abadgaran
                  </a>
							<br />
							<a dir="ltr" href="" className="text-light">
								<i className="fab fa-telegram pr-1 pt-1"></i>
                    abadgaran
                  </a>
						</div>
						<div className="flexed justify-between my-3">
							<div className="bg-light radius-8 flexed fl-c" style={{ width: 100, height: 100 }}>به زودی</div>
							<div className="bg-light radius-8 flexed fl-c" style={{ width: 100, height: 100 }}>به زودی</div>
							<div className="bg-light radius-8 flexed fl-c" style={{ width: 100, height: 100 }}>به زودی</div>
						</div>
					</div>

				</div>
			</div>
		</footer>
	)
}