import React from 'react'

import TopNav from './../components/TopNav'
import Footer from './../components/Footer'

import About from './../images/about.jpg'

function Page() {
  return (
    <div>
      <TopNav active="about" />
      <div className="slideInTop">

        <section>
          <div>
            <img src={About} alt="" className="w-100 blocked force" style={{ height: 512 }} />
          </div>
        </section>

        <section className="pt-5">
          <div className="container py-3">
            <h3 className="text-center">ما کی هستیم</h3>
            <hr />
            <div className="row justify-c py-3">
              <div className="col-md-10">
                <p className="pt-3 mb-0 line-2">
                  مجموعه رفاهی ورزشی آبادگران از سال 1391فعالیت خود را  با دارا بودن استخر استاندارد و تالارهای پذیرایی، غار نمک، سالن ماساژ و سالن بدنسازی افتخار ارائه خدمات ورزشی ورفاهی جهت ارتقا سلامت اجتماعی شروع نمودو با برگزاری مسابقات بین المللی شنا کشورهای اسلامی آسیا به افتخارات خدمتگزاری خود افزود.
                </p>
              </div>
            </div>

          </div>
        </section>

        <Footer />

      </div>
    </div >
  )
}

export default Page
